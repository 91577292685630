
import { PageBase } from '@/core/models/shared';
import { RelatorioService } from '@/core/services/financeiro';
import { Component } from 'vue-property-decorator';

@Component
export default class RelDespesaRateada extends PageBase { 
    service = new RelatorioService();
    item:any = {};
    pizzaChartData: any ={}
    overlay: boolean = false; 
    filter:any = {};
  
    mounted() {
        this.filter = this.$route.query;
        this.overlay = true;  

        this.service.ListarRelatorioDespesaRateada(this.filter.tipoId, this.filter.empreendimentoId, this.filter.fornecedorId, this.filter.empresaId, this.filter.formaPagamentoId, this.filter.dataVencimentoInicial, this.filter.dataVencimentoFinal, this.filter.dataBaixaInicial, this.filter.dataBaixaFinal, this.filter.dataEmissaoInicial,  this.filter.dataEmissaoFinal, this.filter.contaGerencialId)
            .then(
                res => {
                    this.item = res.data;
                    this.item.classificacoes.sort((a, b) => b.percentual - a.percentual)
                    this.pizzaChartData = {

                    datasets: 
                    [{
                        label: 'Valor',
                        backgroundColor: [
                        'rgba(225, 189, 102, 0.9)',
                        'rgba(41, 151, 138, 0.7)', 
                        'rgba(37, 68, 80, 0.7)',    
                        'rgba(223, 107, 78, 0.7)', 
                        'rgba(119, 42, 184, 0.7)'  
                        ],
                        borderColor: [
                        'rgba(225, 189, 102, 1)', 
                        'rgba(41, 151, 138, 1)',  
                        'rgba(37, 68, 80, 1)',   
                        'rgba(223, 107, 78, 1)', 
                        'rgba(119, 42, 184, 1)'
                        ],
                         data: this.item.classificacoes.slice(0, 5)
                        .map((item) => item.valor),
                     }]
                    }
                 },
                err => {
                    if (!err.response) {
                        this.$swal("Aviso", "Não foi possível acessar a API", "error");
                    } 
                    else {
                        this.$swal("Aviso", err.response.data, "error");
                    }
                }
            ).finally(() =>{ 
                this.overlay = false;
            }
        );
    } 
}
